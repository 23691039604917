import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'

import { buildActionAnalyticsAttributes } from '@mondough/analytics-v2'
import { Text, Title } from '@mondough/monzo-ui'

import { ActionButton, Loading } from '../components'
import { ReferralContext, TabContext } from '../providers'
import logoutIcon from '../public/images/icons/logout.svg'
import {
  Tab,
  getAuthValue,
  getCDNSrc,
  getOwedAmount,
  getUserProfileSrc,
  userLogout,
} from '../utils'
import styles from './index.module.scss'

const getSubtitle = (tab: Tab) => {
  if (tab.status !== 'open') {
    return tab.closed_at
      ? `Archived ${new Date(tab.closed_at).toLocaleDateString()}`
      : 'Archived'
  }
  return (
    (getOwedAmount(tab) > 0 ? 'You are owed ' : 'You owe ') +
    Math.abs(getOwedAmount(tab) / 100).toFixed(2)
  )
}

const getFirstThreeParticipants = (tab: Tab) => {
  const userId = getAuthValue('userId')
  const otherPeople = tab.participants.filter(
    (person) => person.user_id !== userId,
  )
  const firstThreeParticipants = otherPeople.slice(0, 3)
  return firstThreeParticipants.map((p) => ({
    name: p.name,
    src: getUserProfileSrc(p.user_id),
  }))
}

export default function Homepage() {
  const [loading, setLoading] = useState(true)
  const [activeTabs, setActiveTabs] = useState<Tab[]>([])
  const [settledTabs, setSettledTabs] = useState<Tab[]>([])
  const { tabs: userTabs } = useContext(TabContext)
  const { setReferral } = useContext(ReferralContext)

  const router = useRouter()

  useEffect(() => {
    const onLoad = async () => {
      setReferral(null)
      setLoading(true)
      setActiveTabs(
        userTabs ? userTabs.filter((tab) => tab.status === 'open') : [],
      )
      setSettledTabs(
        userTabs ? userTabs.filter((tab) => tab.status !== 'open') : [],
      )
      if (userTabs) setLoading(false)
    }
    void onLoad()
    return () => {
      setLoading(false)
      setActiveTabs([])
      setSettledTabs([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTabs])

  if (loading) {
    return <Loading />
  }

  return (
    <section className={styles.wrapper}>
      <Title is="h1" size="medium" className={styles['wrapper-title']}>
        Your Shared Tabs
      </Title>
      {activeTabs.length ? (
        activeTabs.map((tab, i) => (
          <ActionButton
            key={tab.id}
            title={tab.name}
            subtitle={getSubtitle(tab)}
            connectedDown={i !== activeTabs.length - 1}
            connectedUp={i !== 0}
            participants={getFirstThreeParticipants(tab)}
            onClick={async () => {
              await router.push('/tab/[tabId]', `/tab/${tab.id}`)
            }}
            {...buildActionAnalyticsAttributes('tab', {
              tabId: tab.id,
            })}
          />
        ))
      ) : (
        <Text bold className={styles['no-active-tabs']}>
          It doesn't look like you're a part of any active tabs
        </Text>
      )}
      {settledTabs.length ? (
        <Title
          is="h2"
          size="medium"
          className={styles['wrapper-settled-title']}
        >
          Settled tabs
        </Title>
      ) : null}
      {settledTabs.map((tab, i) => (
        <ActionButton
          key={tab.id}
          title={tab.name}
          subtitle={getSubtitle(tab)}
          connectedDown={i !== settledTabs.length - 1}
          connectedUp={i !== 0}
          isDisabled
          participants={getFirstThreeParticipants(tab)}
        />
      ))}
      <ActionButton
        title="Log out"
        subtitle="You can log back in at any time"
        onClick={async () => {
          await userLogout(true)
        }}
        assetLeft={{
          iconSrc: getCDNSrc(logoutIcon),
        }}
        mobileOnly
        {...buildActionAnalyticsAttributes('logout')}
      />
    </section>
  )
}

Homepage.analytics = { name: 'homepage' }
